export const errorLib = {
	ERROR_BAD_REQUEST:
		'Something went wrong. Please check the details and try again.',
	ERROR_NOT_AUTHORIZED: 'You do not have permission to perform this action.',
	ERROR_NOT_FOUND: 'The requested resource could not be found.',
	ERROR_LOGIN_FAILED: 'Login failed. Please check your username or password.',
	ERROR_NO_SUFFICIENT_INFORMATION:
		'Insufficient information provided. Please complete all required fields.',
	ERROR_WRONG_REQUEST: 'Invalid request. Please try again.',
	ERROR_USERNAME_EXISTS:
		'This username is already taken. Please choose another one, or if you already have an account, log in instead.',
	ERROR_EMAIL_EXISTS:
		'This email address is already registered. Please use a different email or login.',
	ERROR_EMAIL_NOT_VERIFIED:
		'Your email address is not verified. Please check your inbox for the verification link.',
	ERROR_RESET_PASSWORD_EXPIRED:
		'The reset password link has expired. Please request a new one.',
	ERROR_FAILED_CREATE_AFFILIATE:
		'Failed to create affiliate account. Please try again later.',
	ERROR_INVALID_RESET_PASSWORD_TOKEN:
		'Invalid reset password token. Please request a new password reset.',
	ERROR_RESET_PASSWORD_FAILED_AUTH:
		'Authentication failed for password reset. Please try again.',
	ERROR_RESET_PASSWORD_FAILED_FIREBASE:
		'An error occurred while resetting your password. Please try again.',
	ERROR_TOKEN_NOT_FOUND:
		'Session expired or invalid token. Please login again.',
	ERROR_REGISTRATION_FAILED:
		'Registration failed. Please check your details and try again.',
	ERROR_EMAIL_USER_NOT_FOUND: 'No account found with this email address.',
	ERROR_EMAIL_VERIFICATION_FAILED:
		'Email verification failed. Please try again or request a new verification link.',
	ADMIN_ONLY_OPERATION:
		"This action is restricted to administrators only. Please log in with an administrator account or contact your system administrator if you believe you're seeing this message in error.",
	ARGUMENT_ERROR:
		'There was a problem with the information provided. Please check your inputs and try again. If this issue persists, contact support.',
	APP_NOT_AUTHORIZED:
		'This application is not authorized to perform this operation. Please ensure you have the necessary permissions and try again. If the issue persists, please contact support.',
	APP_NOT_INSTALLED:
		'The required application is not installed on your device. Please install the application and try again.',
	CAPTCHA_CHECK_FAILED:
		'The CAPTCHA verification failed. Please try again. Make sure to complete the CAPTCHA challenge correctly to proceed.',
	CODE_EXPIRED:
		'Your code has expired. Please request a new code and try again.',
	CORDOVA_NOT_READY:
		'The application is not ready yet. Please wait a few moments and try again. If this message persists, restart the app.',
	CORS_UNSUPPORTED:
		'This operation is not supported by your browser due to security settings. Please try using a different browser or contact support for further assistance.',
	CREDENTIAL_ALREADY_IN_USE:
		'This account is already in use with another sign-in method. Please sign in using the original method or use a different account.',
	CREDENTIAL_MISMATCH:
		'The provided credentials do not match our records. Please check your information and try again.',
	CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
		'For your security, please sign in again to verify your identity. This is required for sensitive operations.',
	DEPENDENT_SDK_INIT_BEFORE_AUTH:
		'There was an error initializing the service. Please ensure that authentication is set up correctly and try again.',
	DYNAMIC_LINK_NOT_ACTIVATED:
		'The link you clicked seems to be inactive or broken. Please check the link or request a new one.',
	EMAIL_CHANGE_NEEDS_VERIFICATION:
		'Your email change request has been received. Please check your email inbox (and spam folder) for a verification link to confirm this change.',
	EMAIL_EXISTS:
		"An account with this email already exists. If this account is yours, please sign in. If you've forgotten your password, you can reset it.",
	EMULATOR_CONFIG_FAILED:
		'There was an error with the emulator configuration. Please check your setup and try again. Contact support if this issue persists.',
	EXPIRED_OOB_CODE:
		'The code you entered has expired. Please request a new one and try again.',
	EXPIRED_POPUP_REQUEST:
		'Your sign-in request has timed out. Please try again. Make sure to complete the sign-in process promptly.',
	INTERNAL_ERROR:
		'An unexpected error occurred. Please try again later. If the problem persists, contact support for assistance.',
	INVALID_API_KEY:
		"The API key provided is invalid. Please check your configuration and try again. If you're unsure, contact your administrator or support.",
	INVALID_APP_CREDENTIAL:
		"The app credentials are invalid. Please check your app's configuration and try again.",
	INVALID_APP_ID:
		"The app ID provided is incorrect. Please verify your app's ID and try again.",
	INVALID_AUTH:
		'Your session has expired or is invalid. Please sign in again.',
	INVALID_AUTH_EVENT:
		'An error occurred during authentication. Please try the process again.',
	INVALID_CERT_HASH:
		'The certificate hash provided is invalid. Please check your SSL certificate configuration.',
	INVALID_CODE:
		'The verification code entered is incorrect. Please check the code and try again.',
	INVALID_CONTINUE_URI:
		'The continue URL is not valid. Please check the URL and try again.',
	INVALID_CORDOVA_CONFIGURATION:
		"There's a problem with the Cordova configuration. Please check your setup and try again.",
	INVALID_CUSTOM_TOKEN:
		'The custom token provided is invalid. Please check the token and try again.',
	INVALID_DYNAMIC_LINK_DOMAIN:
		'The dynamic link domain provided is not valid. Please check the link and try again.',
	INVALID_EMAIL:
		'The email address entered is not valid. Please check the email address and try again.',
	INVALID_EMULATOR_SCHEME:
		'The emulator scheme provided is invalid. Please check your emulator setup and try again.',
	INVALID_IDP_RESPONSE:
		'There was a problem with the sign-in response from the identity provider. Please try again.',
	INVALID_LOGIN_CREDENTIALS:
		'The login credentials provided are incorrect. Please check your credentials and try again.',
	INVALID_MESSAGE_PAYLOAD:
		'The message payload is invalid. Please check your input and try again.',
	INVALID_MFA_SESSION:
		'The multi-factor authentication session is invalid. Please start the process over again.',
	INVALID_OAUTH_CLIENT_ID:
		'The OAuth client ID is invalid. Please check the ID and try again.',
	INVALID_OAUTH_PROVIDER:
		'The selected OAuth provider is invalid or not supported. Please choose a valid provider and try again.',
	INVALID_OOB_CODE:
		'The action code has expired or is invalid. Please request a new one and try again.',
	INVALID_ORIGIN:
		"Your domain is not authorized to perform this action. Please check your domain's permissions and try again.",
	INVALID_PASSWORD:
		'The password entered is incorrect. Please check your password and try again.',
	INVALID_PERSISTENCE:
		'The selected persistence type is not supported in this environment. Please choose a different option and try again.',
	INVALID_PHONE_NUMBER:
		'The phone number entered is not valid. Please check the number and try again.',
	INVALID_PROVIDER_ID:
		"The provider ID is invalid. Please check your provider's configuration and try again.",
	INVALID_RECIPIENT_EMAIL:
		"The recipient's email address is invalid. Please check the email address and try again.",
	INVALID_SENDER:
		"The sender's information is invalid. Please check your configuration and try again.",
	INVALID_SESSION_INFO:
		'The session information is invalid. Please start the verification process over again.',
	INVALID_TENANT_ID:
		'The tenant ID provided is not recognized. Please check the ID and try again or contact support for assistance.',
	MFA_INFO_NOT_FOUND:
		"Multi-factor authentication information could not be found. Please ensure it's set up correctly or contact support.",
	MFA_REQUIRED:
		'Multi-factor authentication is required for this account. Please complete the additional verification steps to proceed.',
	MISSING_ANDROID_PACKAGE_NAME:
		'The Android package name is missing. Please provide it in the configuration and try again.',
	MISSING_APP_CREDENTIAL:
		'App credentials are missing. Please ensure your app is configured correctly and try again.',
	MISSING_AUTH_DOMAIN:
		"The authentication domain configuration is missing. Please add it to your app's configuration and try again.",
	MISSING_CODE:
		'The verification code is missing. Please enter the code and try again.',
	MISSING_CONTINUE_URI:
		'The continue URL is missing. Please provide it and try again.',
	MISSING_IFRAME_START:
		'The iframe start is missing. Please ensure your browser settings allow iframes and try again.',
	MISSING_IOS_BUNDLE_ID:
		'The iOS bundle ID is missing. Please provide it in the configuration and try again.',
	MISSING_OR_INVALID_NONCE:
		'The nonce is missing or invalid. Please try again or contact support for help.',
	MISSING_MFA_INFO:
		'Multi-factor authentication setup information is missing. Please complete all necessary steps for multi-factor setup.',
	MISSING_MFA_SESSION:
		'Multi-factor authentication session is missing. Please start the sign-in process again.',
	MISSING_PHONE_NUMBER:
		'A phone number is required for this operation. Please enter a phone number and try again.',
	MISSING_SESSION_INFO:
		'Verification session information is missing. Please restart the verification process.',
	MODULE_DESTROYED:
		'The application has been stopped or is not initialized correctly. Please refresh or reopen the app.',
	NEED_CONFIRMATION:
		'An account using different credentials exists. Please confirm if you want to link these accounts or sign in using the existing credentials.',
	NETWORK_REQUEST_FAILED:
		'A network error occurred. Please check your internet connection and try again.',
	NULL_USER: 'No user is currently signed in. Please sign in and try again.',
	NO_AUTH_EVENT:
		'No authentication event found. Please try signing in again.',
	NO_SUCH_PROVIDER:
		'The specified provider is not supported. Please choose a valid authentication provider and try again.',
	OPERATION_NOT_ALLOWED:
		"This operation is not allowed. Please check your app's settings or contact support.",
	OPERATION_NOT_SUPPORTED:
		'This operation is not supported in the current environment. Please check your setup or try a different method.',
	POPUP_BLOCKED:
		'A popup required for authentication was blocked. Please allow popups for this site and try again.',
	POPUP_CLOSED_BY_USER:
		'The authentication popup was closed before completion. Please try again.',
	PROVIDER_ALREADY_LINKED:
		'This provider is already linked to the account. Please use a different provider or contact support for assistance.',
	QUOTA_EXCEEDED:
		'The operation cannot be completed because a quota has been exceeded. Please try again later or contact support.',
	REDIRECT_CANCELLED_BY_USER:
		'The sign-in process was cancelled. Please try again if this was a mistake.',
	REDIRECT_OPERATION_PENDING:
		'A sign-in operation is already in progress. Please complete the ongoing operation before starting a new one.',
	REJECTED_CREDENTIAL:
		'The provided credentials were rejected. Please check them and try again.',
	SECOND_FACTOR_ALREADY_ENROLLED:
		'This second-factor method is already enrolled. Please choose a different method or contact support for assistance.',
	SECOND_FACTOR_LIMIT_EXCEEDED:
		'You have reached the maximum number of second-factor methods allowed. Please manage your existing methods or contact support.',
	TENANT_ID_MISMATCH:
		'The tenant ID does not match the expected ID. Please check your settings and try again.',
	TIMEOUT:
		'The request timed out. Please check your internet connection and try again.',
	TOKEN_EXPIRED: 'Your session has expired. Please sign in again.',
	TOO_MANY_ATTEMPTS_TRY_LATER:
		'Too many attempts have been made. Please try again later.',
	UNAUTHORIZED_DOMAIN:
		'This domain is not authorized for the operation. Please check the URL or contact support for help.',
	UNSUPPORTED_FIRST_FACTOR:
		'This sign-in method is not supported. Please choose a different method to sign in.',
	UNSUPPORTED_PERSISTENCE:
		'This type of browser storage is not supported. Please try a different browser or contact support.',
	UNSUPPORTED_TENANT_OPERATION:
		'This operation is not supported for the current organization. Please contact your administrator.',
	UNVERIFIED_EMAIL:
		'Your email address is not verified. Please check your inbox for a verification email or request a new one.',
	USER_CANCELLED:
		'The operation was cancelled by the user. Please try again if this was a mistake.',
	USER_DELETED:
		'The user account could not be found. It may have been deleted.',
	USER_DISABLED:
		'This user account has been disabled. Please contact support for further assistance.',
	USER_MISMATCH:
		'The signed-in user does not match the user in request. Please sign out and sign in again with the correct account.',
	USER_SIGNED_OUT:
		'You have been signed out. Please sign in again to continue.',
	WEAK_PASSWORD:
		'The password you entered is too weak. Please choose a stronger password and try again.',
	WEB_STORAGE_UNSUPPORTED:
		'Web storage is not supported by your browser. Please try a different browser or enable web storage.',
	ALREADY_INITIALIZED:
		"The service has already been initialized. Please refresh the page if you're experiencing issues.",
	RECAPTCHA_NOT_ENABLED:
		'reCAPTCHA is not enabled but is required. Please enable it in your settings and try again.',
	MISSING_RECAPTCHA_TOKEN:
		'The reCAPTCHA token is missing. Please complete the reCAPTCHA challenge and try again.',
	INVALID_RECAPTCHA_TOKEN:
		'The reCAPTCHA token is invalid. Please refresh the page and complete the reCAPTCHA challenge again.',
	INVALID_RECAPTCHA_ACTION:
		'The reCAPTCHA action is invalid. Please ensure you are completing the challenge correctly and try again.',
	MISSING_CLIENT_TYPE:
		'Client type information is missing. Please refresh the page or contact support for help.',
	MISSING_RECAPTCHA_VERSION:
		'The reCAPTCHA version is missing. Please ensure your setup is correct and try again.',
	INVALID_RECAPTCHA_VERSION:
		'The reCAPTCHA version provided is not supported. Please check your configuration and try again.',
	INVALID_REQ_TYPE:
		'The request type is invalid. Please check your request and try again.',
};
