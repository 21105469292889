import React, { useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Badge, IconButton, Popover, Tooltip } from '@mui/material';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { uiActions } from '../../../../redux/actions/ui';
import Notifications from '../../../Notifications';
import ThemeToggle from './ThemeToggle';
import { NotificationsDrawerWrapper } from '../../styles';

const HeaderSettings = () => {
	const dispatch = useDispatch();
	const ref = useRef(null);
	const { settingsOpen, notificationsOpen } = useSelector(
		({ ui }) => ui,
		shallowEqual
	);

	const openSettings = () => {
		dispatch(uiActions.toggleSettingsAction(true));
	};
	const openNotifications = () => {
		dispatch(uiActions.toggleNotificationsAction(true));
	};
	const closeSettings = () => {
		dispatch(uiActions.toggleSettingsAction(false));
	};
	const closeNotifications = () => {
		dispatch(uiActions.toggleNotificationsAction(false));
	};

	return (
		<>
			<Tooltip arrow title='Notifications'>
				<IconButton
					color='primary'
					onClick={openNotifications}
					sx={{ mr: 1 }}
				>
					<Badge badgeContent={0} color='error'>
						<NotificationsActiveTwoToneIcon />
					</Badge>
				</IconButton>
			</Tooltip>
			<Tooltip arrow title='Settings'>
				<IconButton
					color='primary'
					ref={ref}
					onClick={openSettings}
					sx={{ mr: 1 }}
				>
					<SettingsTwoToneIcon />
				</IconButton>
			</Tooltip>
			<Popover
				anchorEl={ref.current}
				onClose={closeSettings}
				open={settingsOpen}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<ThemeToggle />
			</Popover>
			<NotificationsDrawerWrapper
				anchor='right'
				open={notificationsOpen}
				onClose={closeNotifications}
				variant='temporary'
				PaperProps={{
					sx: {
						zIndex: 1302,
						height: 'min-content',
						maxHeight: 'calc(100vh - 68px) !important',
						top: '68px',
					},
				}}
				elevation={9}
			>
				<Notifications />
			</NotificationsDrawerWrapper>
		</>
	);
};

export default HeaderSettings;
