import { all } from 'redux-saga/effects';
import { rootMain } from './init';
import { rootAuth } from './auth';
import { rootUser } from './user';
import { rootLocales } from './locales';
import { rootDashboard } from './dashboard';
import { rootOperations } from './operations';
import { rootWallet } from './wallet';

export default function* rootSaga() {
	yield all([
		rootMain(),
		rootUser(),
		rootAuth(),
		rootWallet(),
		rootLocales(),
		rootDashboard(),
		rootOperations(),
	]);
}
