import { createAction } from '@reduxjs/toolkit';

export const userActions = {
	getUserDataAction: createAction('user/getUserData'),
	setUserInfoAction: createAction('user/setUserInfo'),
	updateUserDataAction: createAction('user/updateUserData'),
	updateProfileAction: createAction('user/updateProfile'),
	changePasswordAction: createAction('user/changePassword'),
	setFormSentStatusAction: createAction('user/setFormSentStatus'),
	setErrorAction: createAction('user/setError'),
};
