import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { themeCreator } from './base';

export const ThemeContext = React.createContext();

const MuiThemeProvider = function (props) {
	const { appTheme } = useSelector(({ ui }) => ui, shallowEqual);
	const curThemeName = appTheme || 'GreyGooseTheme';
	const [themeName, _setThemeName] = useState(curThemeName);
	const theme = themeCreator(themeName);
	const setThemeName = (themeName) => {
		localStorage.setItem('appTheme', themeName);
		_setThemeName(themeName);
	};

	useEffect(() => {
		_setThemeName(curThemeName);
	}, [curThemeName]);

	return (
		<StylesProvider injectFirst>
			<ThemeContext.Provider value={setThemeName}>
				<ThemeProvider theme={theme}>
					{props.children}
				</ThemeProvider>
			</ThemeContext.Provider>
		</StylesProvider>
	);
};

export default MuiThemeProvider;
