import { Alert, styled } from '@mui/material';

export const AlertStyled = styled(Alert)(
	({ theme }) => `
        &.success {
            color: ${theme.colors.success.dark};
            border: 1px solid ${theme.colors.success.main};

            svg {
                color:  ${theme.colors.success.main};
            }
        }
        &.warning {
            color: ${theme.colors.warning.dark};
            border: 1px solid ${theme.colors.warning.main};

            svg {
                color:  ${theme.colors.warning.main};
            }
        }
        &.error {
            color: ${theme.colors.error.main};
            border: 1px solid ${theme.colors.error.light};
        }
        &.info {
            color: ${theme.colors.info.dark};
            border: 1px solid ${theme.colors.info.main};

            svg {
                color:  ${theme.colors.info.main};
            }
        }
`
);
