import React from 'react';
import { Box } from '@mui/material';

const TabPanel = ({ children, value, index, cs, ...other }) => {
	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			{...other}
			sx={{ pt: 2, ...cs }}
		>
			{value === index && children}
		</Box>
	);
};

export default TabPanel;
