import React, { useState, Fragment } from 'react';
import { Box, Divider, Tab, Tabs, Typography } from '@mui/material';
import TabPanel from '../TabPanel';
import Notification from './Notification';
import StatusMenu from './StatusMenu';
import { NotificationsDrawer, ChipStyled, TabLabel } from './styles';
import { tabs, panels, getInfo } from './helpers';

const notifications = [
	// {
	// 	id: 1,
	// 	image: '',
	// 	status: 'unread',
	// 	time_created: '2023-10-05',
	// 	header: 'Something`s happening',
	// 	extra: 'blablabla',
	// },
	// {
	// 	id: 2,
	// 	image: '',
	// 	status: 'archived',
	// 	time_created: '2023-04-08',
	// 	header: 'Something happend',
	// 	extra: 'lalala',
	// },
	// {
	// 	id: 3,
	// 	image: '',
	// 	status: 'unread',
	// 	time_created: '2023-10-09',
	// 	header: 'Something`s happening again',
	// },
];

const Notifications = () => {
	const [value, setValue] = useState(0);
	const handleChange = (_, newValue) => {
		setValue(newValue);
	};

	return (
		<NotificationsDrawer>
			<Box className='stickyHeader'>
				<Typography variant='h4'>Notifications</Typography>
				<StatusMenu
					notifications={notifications}
					handleChange={handleChange}
				/>
			</Box>
			<Tabs
				value={value}
				onChange={handleChange}
				indicatorColor='secondary'
				className='stickyTabs'
			>
				{tabs?.map((el) => {
					const count = getInfo(notifications, el.id, 'count');

					return (
						<Tab
							key={el.index}
							color={el.color}
							label={
								<TabLabel>
									<Typography mr={1}>{el.label}</Typography>
									<ChipStyled
										className={el.color}
										label={count}
										size='small'
									/>
								</TabLabel>
							}
						/>
					);
				})}
			</Tabs>
			{panels?.map((el, j) => {
				const filteredNotifications = getInfo(notifications, el.value);
				return (
					<TabPanel
						key={el.index}
						value={value}
						index={j}
						cs={{ p: 0 }}
					>
						{filteredNotifications?.length ? (
							filteredNotifications?.map((not) => (
								<Fragment key={not.id}>
									<Notification data={not} />
									<Divider />
								</Fragment>
							))
						) : (
							<Box p={2}>
								<Typography textAlign='center'>
									No new notifications
								</Typography>
							</Box>
						)}
					</TabPanel>
				);
			})}
		</NotificationsDrawer>
	);
};

export default Notifications;
