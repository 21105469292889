import { createReducer } from '@reduxjs/toolkit';
import { uiActions } from '../actions/ui';
import { stringifyText } from '../../utils/text';
import { errorLib } from '../../utils/errorLib';

const uiState = {
	appTheme: 'GreyGooseTheme',
	sidebarOpen: false,
	settingsOpen: false,
	profileOpen: false,
	modalOpen: false,
	notificationsOpen: false,
	loading: false,
	alert: {
		show: false,
		severity: 'info',
		body: '',
	},
	navPath: null,
};

const uiReducer = createReducer(uiState, (builder) => {
	builder
		.addCase(
			uiActions.setAlertAction,
			(state, { payload: { show, severity, body } }) => {
				state.alert = {
					show,
					severity,
					body: errorLib[stringifyText(body)] || stringifyText(body),
				};
			}
		)
		.addCase(uiActions.setNavPathAction, (state, { payload }) => {
			state.navPath = payload;
		})
		.addCase(uiActions.startLoaderAction, (state) => {
			state.loading = true;
		})
		.addCase(uiActions.endLoaderAction, (state) => {
			state.loading = false;
		})
		.addCase(uiActions.setThemeAction, (state, { payload }) => {
			state.appTheme = payload;
		})
		.addCase(uiActions.toggleSidebarAction, (state, { payload }) => {
			state.sidebarOpen = !state.sidebarOpen;
		})
		.addCase(uiActions.toggleSettingsAction, (state, { payload }) => {
			state.settingsOpen = payload;
		})
		.addCase(uiActions.toggleProfileAction, (state, { payload }) => {
			state.profileOpen = payload;
		})
		.addCase(uiActions.toggleModalOpenAction, (state, { payload }) => {
			state.modalOpen = payload;
		})
		.addCase(uiActions.toggleNotificationsAction, (state, { payload }) => {
			state.notificationsOpen = payload;
		})
		.addDefaultCase((state) => state);
});

export default uiReducer;
