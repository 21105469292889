import React from 'react';
import { Box } from '@mui/material';
import HeaderSettings from './HeaderSettings';

const HeaderButtons = () => {
	return (
		<Box sx={{ display: 'block' }}>
			<HeaderSettings />
		</Box>
	);
};

export default HeaderButtons;
