export const capitalize = (string) => {
	let str = string.charAt(0).toUpperCase() + string.slice(1);
	return str.replace(/_/, ' ');
};
export const stringifyText = (error) => {
	if (typeof error === 'string') {
		return error;
	} else if (Array.isArray(error)) {
		return error.join(', ');
	} else if (typeof error === 'object' && error !== null) {
		if (error.message) {
			return error.message;
		}
		return JSON.stringify(error);
	} else {
		return 'An error occurred';
	}
};
