import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Button, Collapse, ListItem } from '@mui/material';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { uiActions } from '../../../redux/actions/ui';
import { MenuWrapper, SubMenuWrapper } from '../styles';

const SidebarMenu = ({ isDrawer }) => {
	const dispatch = useDispatch();
	const [state, setState] = useState();
	const userMenu = [
		{
			path: 'dashboard',
			caption: 'Dashboard',
			icon: <HomeOutlinedIcon />,
		},
		{
			path: 'tools',
			caption: 'Marketing Tools',
			icon: <LocalOfferOutlinedIcon />,
		},
		{
			path: 'report/aff_traffic',
			caption: 'Reports',
			icon: <AssessmentOutlinedIcon />,
		},
		{
			path: 'wallet',
			caption: 'Wallet',
			icon: <AccountBalanceWalletOutlinedIcon />,
		},
		{
			path: 'postback',
			caption: 'Postback',
			icon: <AutorenewOutlinedIcon />,
		},
		{
			path: 'commission_plan',
			caption: 'Commission Plan',
			icon: <AssignmentOutlinedIcon />,
		},
		{
			path: 'compliance',
			caption: 'Compliance',
			icon: <VerifiedUserOutlinedIcon />,
		},
		{
			path: 'help',
			caption: 'Help',
			icon: <HelpOutlineIcon />,
		},
	];

	const toggleMenu = (caption) => {
		setState((state) => ({
			...state,
			[caption]: !state[caption],
		}));
	};

	useEffect(() => {
		const nested =
			userMenu &&
			userMenu.filter((el) => el.sub).map((el) => [el.caption, false]);
		const stateObj = Object.fromEntries(nested);
		setState(stateObj);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{state &&
				_buildMenu({ state, userMenu, toggleMenu, dispatch, isDrawer })}
		</>
	);
};

const _buildMenu = ({
	state,
	userMenu,
	toggleMenu,
	parent,
	dispatch,
	isDrawer,
}) => {
	return (
		<MenuWrapper>
			<SubMenuWrapper>
				{userMenu?.map((el, i) => {
					if (el.sub) {
						return _buildSubMenuItem(
							state,
							el,
							i,
							toggleMenu,
							dispatch,
							isDrawer
						);
					} else {
						return _buildMenuItem({
							el,
							i,
							parent,
							dispatch,
							isDrawer,
						});
					}
				})}
			</SubMenuWrapper>
		</MenuWrapper>
	);
};

const _buildMenuItem = ({ el, i, parent, dispatch, isDrawer }) => {
	let adjustedPath = parent ? `/${parent}/${el.path}` : `/${el.path}`;
	return (
		<ListItem key={i}>
			<Button
				// activeClassName='Mui-active'
				end
				component={NavLink}
				to={adjustedPath}
				onClick={() =>
					isDrawer && dispatch(uiActions.toggleSidebarAction())
				}
				sx={{ marginTop: el.path === 'help' ? '40px' : 0 }}
				startIcon={el.icon ? el.icon : <i className='fa fa-circle' />}
			>
				{el.caption}
			</Button>
		</ListItem>
	);
};

const _buildSubMenuItem = (state, el, i, toggleMenu, dispatch, isDrawer) => {
	return (
		<ListItem key={i} className='Mui-children'>
			<Button
				className={clsx({
					'Mui-active': state[el.caption],
				})}
				startIcon={el.icon ? el.icon : <i className='fa fa-circle' />}
				endIcon={
					state[el.caption] ? (
						<ExpandLessTwoToneIcon />
					) : (
						<ExpandMoreTwoToneIcon />
					)
				}
				onClick={() => toggleMenu(el.caption)}
			>
				{el.caption}
			</Button>
			<Collapse in={state[el.caption]}>
				{_buildMenu({
					state,
					userMenu: el.sub,
					toggleMenu,
					parent: el.path,
					dispatch,
					isDrawer,
				})}
			</Collapse>
		</ListItem>
	);
};

export default SidebarMenu;
