import React from 'react';
import { LinkStyled } from './styles';

const Link = ({ to, text, target = '_parent', cs }) => {
	return (
		<LinkStyled
			to={to}
			target={target}
			className='MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineHover css-tdnc1t-MuiTypography-root-MuiLink-root'
			style={cs}
		>
			{text}
		</LinkStyled>
	);
};

export default Link;
