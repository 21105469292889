import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { NotBox, DotLegend } from './styles';

const Notification = ({ data }) => {
	return (
		<NotBox>
			<Avatar src={data.image} />
			<Box>
				<Typography
					variant='h5'
					sx={{ pr: '22px', position: 'relative' }}
				>
					{data.header}
					{data.status === 'unread' && <DotLegend />}
				</Typography>
				<Typography variant='subtitle1'>{data.time_created}</Typography>
				{data?.extra && (
					<Typography variant='body1'>{data.extra}</Typography>
				)}
			</Box>
		</NotBox>
	);
};

export default Notification;
