import { createReducer } from '@reduxjs/toolkit';
import { sourcesActions } from '../actions/sources';

const sourcesState = {
	trafficTags: [],
	error: null,
};

const sourcesReducer = createReducer(sourcesState, (builder) => {
	builder
		.addCase(
			sourcesActions.getTrafficTagSuccessAction,
			(state, { payload }) => {
				state.trafficTags = payload;
			}
		)
		.addCase(sourcesActions.setErrorAction, (state) => {
			state.error = true;
		})
		.addDefaultCase((state) => state);
});

export default sourcesReducer;
