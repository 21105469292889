import React from 'react';
import { Box, Button, Menu, Typography } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { getInfo, tabs } from './helpers';
import { ChipStyled, MobileMenuItem } from './styles';

const StatusMenu = ({ notifications, handleChange }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const _handleChange = (_, index) => {
		handleChange(_, index);
		handleClose();
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box>
			<Button
				id='basic-button'
				aria-controls='basic-menu'
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				sx={{ p: 1 }}
			>
				<MenuOpenIcon />
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
					sx: {
						padding: 0,
					},
				}}
			>
				{tabs?.map((el, index) => {
					const count = getInfo(notifications, el.id, 'count');

					return (
						<MobileMenuItem
							key={el.label}
							onClick={(event) => _handleChange(event, index)}
						>
							<Typography mr={1}>{el.label}</Typography>
							<ChipStyled
								className={el.color}
								label={count}
								size='small'
							/>
						</MobileMenuItem>
					);
				})}
			</Menu>
		</Box>
	);
};

export default StatusMenu;
