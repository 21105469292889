import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Box, IconButton, Tooltip } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import HeaderButtons from './HeaderButtons';
import HeaderUserBox from './HeaderUserBox';
import { uiActions } from '../../redux/actions/ui';
import { HeaderWrapper } from './styles';

const Header = () => {
	const sidebarOpen = useSelector(({ ui }) => ui.sidebarOpen, shallowEqual);
	const dispatch = useDispatch();
	const toggleSidebar = () => {
		dispatch(uiActions.toggleSidebarAction());
	};

	return (
		<HeaderWrapper display='flex' alignItems='center' sx={{ p: { xs: 0 } }}>
			<Box sx={{ display: { lg: 'none', md: 'none', xs: 'block' } }}>
				<Tooltip arrow title='Menu'>
					<IconButton color='primary' onClick={toggleSidebar}>
						{!sidebarOpen ? (
							<MenuTwoToneIcon />
						) : (
							<CloseTwoToneIcon />
						)}
					</IconButton>
				</Tooltip>
			</Box>
			<Box display='flex' alignItems='center' />
			<Box display='flex' alignItems='center'>
				<HeaderButtons />
				<HeaderUserBox dispatch={dispatch} />
				<Box sx={{ display: { lg: 'none', md: 'block', xs: 'none' } }}>
					<Tooltip arrow title='Menu'>
						<IconButton color='primary' onClick={toggleSidebar}>
							{!sidebarOpen ? (
								<MenuTwoToneIcon />
							) : (
								<CloseTwoToneIcon />
							)}
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
		</HeaderWrapper>
	);
};

export default Header;
