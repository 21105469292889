import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import Logo from '../Logo';
import Loader from '../Loader';
import history from '../../navigator/history';
import { FallbackWrapper } from './styles';

const FallBack = ({ error, resetErrorBoundary }) => {
	const location = useLocation();

	const doRedirect = () => {
		const doesAnyHistoryEntryExist = location.key;
		if (doesAnyHistoryEntryExist) {
			history.back();
		} else {
			history.push('/dashboard');
		}
	};

	return (
		<FallbackWrapper>
			<Loader />
			<Logo cs={{ mb: 2 }} />
			<Box mb={4} sx={{width: '100%', maxWidth: '500px'}}>
				<Typography variant='h3'>An error occured:</Typography>
				<Typography variant='h3' sx={{ wordBreak: 'break-word' }}>&ldquo;{error?.message}&rdquo;</Typography>
			</Box>
			<Box className='actions'>
				<Button onClick={resetErrorBoundary} variant='outlined'>
					Try again
				</Button>
				<Button onClick={doRedirect} variant='outlined'>
					Return to the previous page
				</Button>
			</Box>
		</FallbackWrapper>
	);
};

export default FallBack;
