import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { LoaderBox } from './styles';

const Loader = ({ cs }) => {
	const [color, setColor] = useState('#2442AF');
	const { appTheme, loading } = useSelector(({ ui }) => ui, shallowEqual);

	useEffect(() => {
		appTheme === 'GreyGooseTheme'
			? setColor('#2442AF')
			: setColor('#6E759F');
	}, [appTheme]);

	return loading ? (
		<LoaderBox>
			<svg
				className='loader_svg'
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
				style={cs?.svg}
			>
				<circle fill={color} stroke='none' cx='6' cy='50' r='6'>
					<animate
						attributeName='opacity'
						dur='1s'
						values='0;1;0'
						repeatCount='indefinite'
						begin='0.1'
					/>
				</circle>
				<circle fill={color} stroke='none' cx='26' cy='50' r='6'>
					<animate
						attributeName='opacity'
						dur='1s'
						values='0;1;0'
						repeatCount='indefinite'
						begin='0.2'
					/>
				</circle>
				<circle fill={color} stroke='none' cx='46' cy='50' r='6'>
					<animate
						attributeName='opacity'
						dur='1s'
						values='0;1;0'
						repeatCount='indefinite'
						begin='0.3'
					/>
				</circle>
			</svg>
		</LoaderBox>
	) : null;
};

export default Loader;
