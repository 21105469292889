import { createAction } from '@reduxjs/toolkit';

export const authActions = {
	signupAction: createAction('auth/signup'),
	signinAction: createAction('auth/signin'),
	signinSuccessAction: createAction('auth/signinSuccess'),
	signinFailureAction: createAction('auth/signinFailure'),
	setNotVerifiedAction: createAction('auth/setNotVerified'),
	setNotApprovedAction: createAction('auth/setNotApproved'),
	setRegistrationSuccess: createAction('auth/setRegistrationSuccess'),
	setSIDAction: createAction('auth/setSID'),
	// verifyEmailAction: createAction('auth/verifyEmail'),
	resendLinkAction: createAction('auth/resendLink'),
	setLinkSentAction: createAction('auth/setLinkSent'),
	forgotPasswordAction: createAction('auth/forgotPassword'),
	prepareLoginAsAffiliateAction: createAction('auth/prepareLoginAsAffiliate'),
	emailReceivedAction: createAction('auth/emailReceived'),
	resetPasswordAction: createAction('auth/resetPassword'),
	resetPasswordSuccessAction: createAction('auth/resetPasswordSuccess'),
	isLoggedInAction: createAction('auth/isLoggedIn'),
	logoutAction: createAction('auth/logout'),
	logoutSuccessAction: createAction('auth/logoutSuccess'),
	googleLoginSuccessAction: createAction('auth/googleLoginSuccess'),
};
