import { styled, Box } from '@mui/material';

export const FallbackWrapper = styled(Box)(
	({ theme }) => `
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-top: 2%;

    .MuiTypography-h3 {
        text-align: center;
    }
    .MuiTypography-subtitle1 {
        text-align: center;
        font-size: 20px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
    .actions {
        display: flex;
        flex-flow: column nowrap;

        button {
            margin-bottom: 8px;
        }
    }
`
);
