import { createReducer } from '@reduxjs/toolkit';
import { localesActions } from '../actions/locales';

const localesState = {
	countries: [],
	countryStates: {},
	currencies: [],
	error: null,
};

const localesReducer = createReducer(localesState, (builder) => {
	builder
		.addCase(
			localesActions.getCountrySuccessAction,
			(state, { payload }) => {
				state.countries = payload;
			}
		)
		.addCase(
			localesActions.getCurrencySuccessAction,
			(state, { payload }) => {
				state.currencies = payload || [];
				state.error = null;
			}
		)
		.addCase(
			localesActions.getCountryStatesSuccessAction,
			(state, { payload }) => {
				const countryStatesTemp = {};		
				const uniqueValues = [
					...new Set(payload.map((item) => item.country_code)),
				];
				uniqueValues.forEach((c) => {
					countryStatesTemp[c] = payload.filter(el => el.country_code === c);
				});
				state.countryStates = countryStatesTemp;
				state.error = null;
			}
		)
		.addCase(localesActions.setErrorAction, (state) => {
			state.error = true;
		})
		.addDefaultCase((state) => state);
});

export default localesReducer;
