import { createReducer } from '@reduxjs/toolkit';
import { userActions } from '../actions/user';

const userState = {
	userInfo: {},
	notifications: {
		news: false,
		productUpdates: true,
		weeklyBlog: false,
		commissionUpdate: false,
		walletUpdates: false,
	},
	isPasswordFormSent: false,
};

const userReducer = createReducer(userState, (builder) => {
	builder
		.addCase(userActions.setUserInfoAction, (state, { payload }) => {
			state.userInfo = { ...state.userInfo, ...payload };
		})
		.addCase(userActions.setFormSentStatusAction, (state, { payload }) => {
			state.isPasswordFormSent = payload;
		})
		.addDefaultCase((state) => state);
});

export default userReducer;
