import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { initActions } from './redux/actions/init';
import Private from './navigator/Private';
import Public from './navigator/Public';
import FallBack from './components/FallbackComponent';
import MuiThemeProvider from './theme/MuiThemeProvider';

const App = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const isAuth = useSelector(({ auth }) => auth.isAuth, shallowEqual);
	const navPath = useSelector(({ ui }) => ui.navPath);
  
	useEffect(() => {
		const initilize = () => {
			dispatch(initActions.initializeAction());
		};

		initilize();
	}, [dispatch]);
	useEffect(() => {
		if (navPath) {
		 	navigate(navPath);
		}
	}, [navPath, navigate]);

	return (
		<MuiThemeProvider>
			<ErrorBoundary FallbackComponent={FallBack} key={location.pathname}>
				{_buildContent(isAuth)}
			</ErrorBoundary>
		</MuiThemeProvider>
	);
};

const _buildContent = (isAuth) => {
	return isAuth ? <Private /> : <Public />;
};

export default App;
