import React, { useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IconButton, Snackbar } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { uiActions } from '../../redux/actions/ui';
import { AlertStyled } from './styles';

const Alert = () => {
	const dispatch = useDispatch();
	const alert = useSelector(({ ui }) => ui.alert, shallowEqual);
	const timeoutRef = useRef(null);

	const handleClose = (_, reason) => {
		if (reason === 'clickaway' && alert.severity === 'error') {
			return;
		}
		dispatch(
			uiActions.setAlertAction({
				show: false,
				severity: alert.severity,
				body: alert.body,
			})
		);
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = setTimeout(() => {
			dispatch(
				uiActions.setAlertAction({
					show: false,
					severity: 'info',
					body: '',
				})
			);
		}, 1000);
	};

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	return (
		<Snackbar
			anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
			autoHideDuration={alert.severity === 'error' ? null : 3000}
			open={alert.show}
			sx={{ top: '7px !important' }}
			onClose={handleClose}
		>
			<AlertStyled
				className={alert.severity}
				severity={alert.severity}
				action={
					alert.severity === 'error' ? (
						<IconButton
							size='small'
							aria-label='close'
							color='error'
							onClick={handleClose}
						>
							<CloseRoundedIcon fontSize='small' />
						</IconButton>
					) : null
				}
			>
				{alert.body}
			</AlertStyled>
		</Snackbar>
	);
};

export default Alert;
