import React from 'react';
import { Route } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import NotRequireAuth from './NotRequireAuth';

// public
const Signin = React.lazy(() => import('../components/Login/Signin'));
const Signup = React.lazy(() => import('../components/Login/Signup'));
const LostPassword = React.lazy(() =>
	import('../components/Login/LostPassword')
);
const FirebaseComponentSwitch = React.lazy(() => import('../components/FirebaseComponentSwitch'));
const LoginAsAffiliate = React.lazy(() => import('../screens/LoginAsAffiliate'));

// private
const Dashboard = React.lazy(() => import('../screens/Dashboard'));
const StatsReport = React.lazy(() => import('../screens/Reports/StatsReport'));
const Wallet = React.lazy(() => import('../screens/Wallet'));
const AccountProfile = React.lazy(() => import('../screens/AccountProfile'));
const MarketingTools = React.lazy(() => import('../screens/MarketingTools'));
const Postback = React.lazy(() => import('../screens/Postback'));
const CommissionPlan = React.lazy(() => import('../screens/CommissionPlan'));
const Compliance = React.lazy(() => import('../screens/Compliance'));
const Help = React.lazy(() => import('../screens/Help'));

const routes = [
	{
		path: '/',
		element: (
			<RequireAuth>
				<Dashboard />
			</RequireAuth>
		),
	},
	{
		path: '/dashboard',
		element: (
			<RequireAuth>
				<Dashboard />
			</RequireAuth>
		),
	},
	{
		path: '/report/aff_traffic',
		element: (
			<RequireAuth>
				<StatsReport />
			</RequireAuth>
		),
	},
	{
		path: '/user_profile',
		element: (
			<RequireAuth>
				<AccountProfile />
			</RequireAuth>
		),
	},
	{
		path: '/wallet',
		element: (
			<RequireAuth>
				<Wallet />
			</RequireAuth>
		),
	},
	{
		path: '/help',
		element: (
			<RequireAuth>
				<Help />
			</RequireAuth>
		),
	},
	{
		path: '/postback',
		element: (
			<RequireAuth>
				<Postback />
			</RequireAuth>
		),
	},
	{
		path: '/commission_plan',
		element: (
			<RequireAuth>
				<CommissionPlan />
			</RequireAuth>
		),
	},
	{
		path: '/compliance',
		element: (
			<RequireAuth>
				<Compliance />
			</RequireAuth>
		),
	},
	{
		path: '/tools',
		element: (
			<RequireAuth>
				<MarketingTools />
			</RequireAuth>
		),
	},
	{
		path: '/signin',
		element: (
			<NotRequireAuth>
				<Signin />
			</NotRequireAuth>
		),
	},
	{
		path: '/signin/:token',
		element: (
			<NotRequireAuth>
				<Signin />
			</NotRequireAuth>
		),
	},
	{
		path: '/signup',
		element: (
			<NotRequireAuth>
				<Signup />
			</NotRequireAuth>
		),
	},
	{
		path: '/lost_password',
		element: (
			<NotRequireAuth>
				<LostPassword />
			</NotRequireAuth>
		),
	},
	{
		path: '/nav',
		element: (
			<NotRequireAuth>
				<FirebaseComponentSwitch />
			</NotRequireAuth>
		),
	},
	{
		path: '/login_as/:token',
		element: (
			<NotRequireAuth>
				<LoginAsAffiliate />
			</NotRequireAuth>
		),
	},
	{
		path: '*',
		element: (
			<RequireAuth>
				<Dashboard />
			</RequireAuth>
		),
	},
];

const RoutesBuilder = (_routes) => {
	return _routes?.map((route, i) => {
		return (
			<Route
				key={i}
				path={route.path}
				element={route.element}
			/>
		);
	});
};
export { routes, RoutesBuilder };
