import { Link } from 'react-router-dom';
import { styled } from '@mui/material';

export const LinkStyled = styled(Link)(
	({ theme }) => `
    margin: 0;
    text-decoration: none;
    color: ${theme.colors.primary.main};
    &:hover {
        text-decoration: underline;
    }
`
);
