import React from 'react';

export const tabs = [
	{
		index: 0,
		id: 'all',
		color: 'secondary',
		label: 'All',
	},
	{
		index: 1,
		id: 'unread',
		color: 'info',
		label: 'Unread',
	},
	{
		index: 2,
		id: 'archived',
		color: 'success',
		label: 'Archived',
	},
];
export const panels = [
	{
		index: 0,
		value: 'all',
		component: () => <div>all notifs</div>,
	},
	{
		index: 1,
		value: 'unread',
		component: () => <div>unread notifs</div>,
	},
	{
		index: 2,
		value: 'archived',
		component: () => <div>archived notifs</div>,
	},
];
export const getInfo = (data, status, type) => {
	let elements =
		status === 'all' ? data : data?.filter((el) => el.status === status);

	return type === 'count' ? elements?.length : elements;
};
