import { createAction } from '@reduxjs/toolkit';

export const localesActions = {
	getCountryAction: createAction('locales/getCountry'),
	getSignUpLocalesAction: createAction('locales/getSignUpLocales'),
	getCountrySuccessAction: createAction('locales/getCountrySuccess'),
	getCountryStateAction: createAction('locales/getCountryState'),
	getCountryStatesSuccessAction: createAction('locales/getCountryStatesSuccess'),
	getCurrencySuccessAction: createAction('locales/getCurrencySuccess'),
	setErrorAction: createAction('locales/setError'),
};
