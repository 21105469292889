import { createReducer } from '@reduxjs/toolkit';
import { authActions } from '../actions/auth';

const authState = {
	isAuth: false,
	xSID: null,
	emailReceived: false,
	reseted: false,
	notVerified: false,
	notApproved: false,
	linkSent: false,
	registrationSuccess: false,
};

const authReducer = createReducer(authState, (builder) => {
	builder
		.addCase(authActions.signinSuccessAction, (state, { payload }) => {
			state.isAuth = payload.userInfo.type ? true : false;
			state.xSID = payload.xSID;
			state.notApproved = false;
			state.notVerified = false;
			state.linkSent = false;
			state.error = false;
		})
		.addCase(authActions.signinFailureAction, (state) => {
			state.isAuth = false;
			state.error = true;
		})
		.addCase(authActions.setRegistrationSuccess, (state) => {
			state.registrationSuccess = true;
		})
		.addCase(authActions.emailReceivedAction, (state) => {
			state.emailReceived = true;
		})
		.addCase(authActions.setSIDAction, (state, { payload }) => {
			state.xSID = payload;
		})
		.addCase(
			authActions.resetPasswordSuccessAction, (state) => {
				state.reseted = true;
			}
		)
		.addCase(
			authActions.setNotVerifiedAction, (state) => {
				state.notVerified = true;
			}
		)
		.addCase(
			authActions.setNotApprovedAction, (state) => {
				state.notApproved = true;
			}
		)
		.addCase(
			authActions.setLinkSentAction, (state) => {
				state.linkSent = true;
			}
		)
		.addCase(authActions.logoutSuccessAction, (state) => {
			state.isAuth = false;
			state.emailReceived = false;
			state.reseted = false;
			state.error = false;
			state.xSID = null;
		})
		.addDefaultCase((state) => state);
});

export default authReducer;
