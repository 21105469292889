import { styled, Box } from '@mui/material';

export const LogoBox = styled(Box)(
	({ theme }) => `
    display: inline-flex;
    align-items: center;
    height: auto;
    box-sizing: border-box !important;

    img {
        width: 70px;

        &.full {
            width: 140px;
        }
    }
  `
);
