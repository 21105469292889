import React from 'react';
import { Routes } from 'react-router-dom';
import Loader from '../components/Loader';
import { PublicContent, PublicWrapper } from './styles';
import { routes, RoutesBuilder } from './routes';

const Public = () => {
	return (
		<PublicWrapper>
			<PublicContent>
				<React.Suspense fallback={<Loader />}>
					<Routes>{RoutesBuilder(routes)}</Routes>
				</React.Suspense>
			</PublicContent>
		</PublicWrapper>
	);
};

export default Public;
