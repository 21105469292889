import { createReducer } from '@reduxjs/toolkit';
import { walletActions } from '../actions/wallet';

const walletState = {
	regulations: [],
	error: null,
};

const walletReducer = createReducer(walletState, (builder) => {
	builder
		.addCase(walletActions.setErrorAction, (state) => {
			state.error = true;
		})
		.addCase(walletActions.getWalletSettingsSuccessAction, (state, { payload }) => {
			const parsedRegulations = payload?.map(el => ({
				...el,
				state: typeof el.state === 'string'
				? JSON.parse(el.state)
				: el.state
			}));
			state.regulations = parsedRegulations;
		})
		.addDefaultCase((state) => state);
});

export default walletReducer;
